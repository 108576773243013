import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { Checkbox, Divider, Input, Tooltip } from "antd";
import useContainerDimensions from "../../use-container-dimensions/Index";
import { extractNumberFromString, useMouseTrap } from "../../../../utils";
import { nanoid } from "nanoid";
import { debounce } from "lodash";
import XLSX from "sheetjs-style";
import { saveAs } from "file-saver";
import Highlighter from "react-highlight-words";
import { CustomModal } from "../Index";
import { CloseOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import MatchWholeWordIcon from "../../../../assets/match-whole-word.svg";

const checkIfCellIsFocused = (rowIndex, colId, id) => {
  const activeElement = document.activeElement;
  const isFocused = activeElement.parentNode.getAttribute("row-index") == rowIndex
    && activeElement.getAttribute("col-id") === colId
    && (!id || activeElement.parentElement.getAttribute("row-id") == id);
  return isFocused;
}

const ensureCellIsFocused = (gridApiRef, rowIndex, colId, id, containerRef) => {
  if (!gridApiRef?.current?.rowModel?.rowsToDisplay?.length) {
    containerRef?.current?.focus?.();
    return
  }
  gridApiRef?.current.ensureIndexVisible(rowIndex, 'middle');
  gridApiRef?.current.setFocusedCell(rowIndex, colId);
  const isCellFocused = checkIfCellIsFocused(rowIndex, colId, id);
  if (!isCellFocused) {
    let maxRetries = 15;
    const timer = setInterval(() => {
      const isCellFocused = checkIfCellIsFocused(rowIndex, colId, id);
      const filteredRows = gridApiRef?.current?.rowModel?.rowsToDisplay?.length;
      const cellExists = rowIndex < filteredRows;
      if (isCellFocused || maxRetries <= 0 || !cellExists) {
        clearInterval(timer);
      } else {
        gridApiRef?.current.ensureIndexVisible(rowIndex, 'middle');
        gridApiRef?.current.setFocusedCell(rowIndex, colId);
        maxRetries--;
      }
    }, 25);
  }
}

const getAutoSizeableColumnIds = (columns) => {
  const colIds = [];
  columns.forEach((col) => {
    if (col.children) {
      colIds.push(...getAutoSizeableColumnIds(col.children));
    }
    if (col.autoSize) {
      colIds.push(col.id);
    }
  });
  return colIds;
};

const autoSizeColumns = (colApi, columns, gridApi, gridId) => {
  const autoSizeableColumnIds = getAutoSizeableColumnIds(columns);
  colApi.autoSizeColumns(autoSizeableColumnIds);
  const gridBodyWidth = document.getElementsByClassName(`grid${gridId}`)[0]?.clientWidth;
  const allColumnsWidth = colApi
    .getAllDisplayedColumns()
    .map((c) => c.getActualWidth())
    .reduce((a, b) => a + b, 0);
  if (gridBodyWidth > allColumnsWidth) {
    gridApi.sizeColumnsToFit();
  }
};

const getGridFirstNavigableColId = (columns) => {
  let colId = undefined;
  columns.forEach((col) => {
    if (!colId) {
      if (col.children) {
        colId = getGridFirstNavigableColId(col.children);
        return;
      }
      if (!col.suppressNavigable) {
        colId = col.field || col.id;
      }
    }
  });
  return colId;
};
const getGridNavigableColIdWithPreference = (gridApi, preferrableColId) => {
  if (!gridApi) {
    return;
  }

  if (preferrableColId) {
    const preferrableColDef = gridApi.columnModel.columnDefs.find(
      (colDef) => colDef.field === preferrableColId
    );
    if (preferrableColDef && !preferrableColDef.suppressNavigable) {
      return preferrableColId;
    }
  }

  return getGridFirstNavigableColId(gridApi.columnModel.columnDefs);
};

export function useMultipleAgGrids() {
  const gridApisRef = useRef([]);
  const cellFocusedCallbacksRef = useRef([]);
  const cellKeyDownCallbacksRef = useRef([]);
  const [focusedGridIndex, setFocusedGridIndex] = useState(-1);

  function setGridApi(gridIndex, gridApi) {
    gridApisRef.current[gridIndex] = gridApi;
    function onCellFocused(e) {
      if (e.rowIndex === null || e.rowIndex === undefined) {
        return;
      }
      if (focusedGridIndex !== gridIndex) {
        setFocusedGridIndex(gridIndex);
      }
      gridApisRef.current
        .filter((_, index) => index !== gridIndex)
        .forEach((api) => {
          if (api.getFocusedCell()) {
            api.clearFocusedCell();
          }
        });
    }

    function onCellKeyDown(e) {
      const keyPressed = e.event.key;
      const columnId = e.colDef.field;
      // Navigate to next grid
      if (keyPressed === "ArrowDown") {
        const rowCount = e.api.rowModel.rowsToDisplay.length;
        const isLastRow = e.rowIndex === rowCount - 1;
        if (isLastRow) {
          const nextGridApi = gridApisRef.current.find(
            (api, i) => i > gridIndex && api.rowModel.rowsToDisplay.length > 0
          );
          if (nextGridApi) {
            const nextGridNavigableColId = getGridNavigableColIdWithPreference(
              nextGridApi,
              columnId
            );
            nextGridApi.setFocusedCell(0, nextGridNavigableColId);
          }
        }
      }
      // Navigate to prev grid
      if (keyPressed === "ArrowUp") {
        const isFirstRow = e.rowIndex === 0;
        if (isFirstRow && gridIndex > 0) {
          const prevGridApi = gridApisRef.current
            .map((api, i) => ({ api, i }))
            .reverse()
            .find(({ api, i }) => i < gridIndex && api.rowModel.rowsToDisplay.length > 0)?.api;
          if (prevGridApi) {
            const nextGridNavigableColId = getGridNavigableColIdWithPreference(
              prevGridApi,
              columnId
            );
            prevGridApi.setFocusedCell(
              prevGridApi.rowModel.rowsToDisplay.length - 1,
              nextGridNavigableColId
            );
          }
        }
      }
    }
    gridApi.addEventListener("cellFocused", onCellFocused);
    gridApi.addEventListener("cellKeyDown", onCellKeyDown);
    cellFocusedCallbacksRef.current[gridIndex] = onCellFocused;
    cellKeyDownCallbacksRef.current[gridIndex] = onCellKeyDown;
  }

  function restoreFocus() {
    if (focusedGridIndex !== -1) {
      const gridApi = gridApisRef.current[focusedGridIndex];
      if (gridApi) {
        gridApi.restoreFocus();
      }
    }
  }

  useEffect(() => {
    return () => {
      gridApisRef.current.forEach((gridApi, gridIndex) => {
        gridApi.removeEventListener("cellFocused", cellFocusedCallbacksRef.current[gridIndex]);
        gridApi.removeEventListener("cellKeyDown", cellKeyDownCallbacksRef.current[gridIndex]);
      });
    };
  }, []);

  return [setGridApi, focusedGridIndex, restoreFocus];
}

function HighlightCellRenderer({ value, context, colDef, rowIndex, valueFormatted }) {
  const searchValue = context?.searchOccurrences?.searchValue;
  const searchRegex = context?.searchOccurrences?.searchRegex;
  const currOccurrence = context?.searchOccurrences?.currentOccurrence || {};
  const stringValue = String(value ?? "");
  if (searchValue && stringValue.toLowerCase?.().match(searchRegex)?.index > -1) {
    return (
      <Highlighter
        searchWords={[searchRegex]}
        autoEscape={false}
        textToHighlight={stringValue}
        activeClassName
        activeIndex={
          currOccurrence.rowIndex === rowIndex && currOccurrence.colId === colDef?.field
            ? currOccurrence.cellOccurrenceIndex
            : undefined
        }
        highlightStyle={{ padding: 0, background: "yellow" }}
        activeStyle={{ padding: 0, background: "orange" }}
      />
    );
  }
  return <span>{valueFormatted ?? value}</span>;
}

function HighlightFullWidthCellRenderer({ context, data, rowIndex, columnApi, node, api }) {
  const searchValue = context?.searchOccurrences?.searchValue;
  const searchRegex = context?.searchOccurrences?.searchRegex;
  const currOccurrence = context?.searchOccurrences?.currentOccurrence || {};
  const allColumns = columnApi.getAllColumns();
  const firstColId = allColumns[0]?.colId === "checkbox" ? allColumns[1]?.colId : allColumns[0]?.colId;
  const value = data[firstColId] ?? "";
  if (!value) {
    return null;
  }
  return (
    <div
      style={{ display: "flex", alignItems: "center", height: "100%" }}
      onClick={(e) => {
        api.setFocusedCell(rowIndex, firstColId)
      }}
      tabIndex={-1}
    >
      <Checkbox checked={node.selected} onClick={() => node.setSelected(!node.selected)} style={{ marginRight: 24 }} />
      {(searchValue && value?.toLowerCase?.().includes(searchValue)) ? (
        <Highlighter
          searchWords={[searchRegex]}
          autoEscape={false}
          textToHighlight={value}
          activeClassName
          activeIndex={
            currOccurrence.rowIndex === rowIndex ? currOccurrence.cellOccurrenceIndex : undefined
          }
          highlightStyle={{ padding: 0, background: "yellow" }}
          activeStyle={{ padding: 0, background: "orange" }}
        />
      ) : (<span tabIndex={-1}>{value}</span>)}
    </div>
  );
}

export const SearchOccurrencesModal = ({
  handleChange,
  handleCancel,
  handleNext,
  handlePrev,
  handleToggleMatchWord,
  context,
  returnFocusFunc,
}) => {
  const inputRef = useRef();
  const activeOccurrenceIndex = context?.activeOccurrenceIndex || 0;
  const totalOccurrences = context?.occurrences?.length || 0;
  const activeOccurrenceIndexToShow = totalOccurrences > 0 ? activeOccurrenceIndex + 1 : 0;
  const matchWholeWord = context?.matchWholeWord;

  useMouseTrap(
    { current: document.body },
    {
      "ctrl+f,command+f": (e) => {
        e.preventDefault();
        inputRef.current?.focus?.();
        inputRef.current?.select?.();
      },
    }
  );
  useMouseTrap({ current: inputRef?.current?.input }, {
    "ctrl+alt+w,command+alt+w": (e) => {
      e.preventDefault();
      handleToggleMatchWord?.();
    },
    "enter": (e) => {
      e.preventDefault();
      handleNext();
    },
    "shift+enter": (e) => {
      e.preventDefault();
      handlePrev();
    }
  }, [handleToggleMatchWord]);
  return (
    <CustomModal
      title={null}
      mask={false}
      onCancel={handleCancel}
      width="400px"
      styles={{
        content: {
          background: "#f5f5f5",
          left: "unset",
          bottom: "unset",
          right: "16px",
          top: "76px",
          position: "fixed",
          padding: "8px 16px",
          border: "1px solid lightgrey"
        }
      }}
      returnFocusFunc={returnFocusFunc}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          color: "var(--text-color-secondary)",
        }}
      >
        <Input
          ref={inputRef}
          style={{ padding: "0px 8px", background: "white" }}
          variant="borderless"
          autoFocus
          onChange={(e) => handleChange(e.target.value)}
        />
        <Tooltip title="Match whole word (Ctrl+Alt+W)" placement="bottom">
          <img
            onClick={handleToggleMatchWord}
            src={MatchWholeWordIcon}
            alt="Match Whole Word"
            style={{
              cursor: "pointer",
              marginLeft: 8,
              width: 24,
              filter: matchWholeWord ?
                `invert(42%) sepia(60%) saturate(3664%) hue-rotate(176deg) brightness(96%) contrast(103%)`
                : `invert(48%) sepia(41%) saturate(2%) hue-rotate(35deg) brightness(96%) contrast(89%)`
            }}
          />
        </Tooltip>
        <span style={{ marginLeft: 8 }}>
          {activeOccurrenceIndexToShow}/{totalOccurrences}
        </span>
        <Divider
          type="vertical"
          style={{ borderLeft: "2px solid lightgrey", margin: "0px 16px", height: "28px" }}
        />
        <Tooltip title="Previous match (Shift+Enter)" placement="bottom">
          <UpOutlined onClick={handlePrev} />
        </Tooltip>
        <Tooltip title="Next match (Enter)" placement="bottom">
          <DownOutlined onClick={handleNext} style={{ marginLeft: 8 }} />
        </Tooltip>
        <Tooltip title="Close (Escape)" placement="bottom">
          <CloseOutlined onClick={handleCancel} style={{ marginLeft: 8 }} />
        </Tooltip>
      </div>
    </CustomModal>
  );
};
const getMatchRegex = (searchValue, matchWholeWord) => {
  return matchWholeWord ? new RegExp(`\\b` + searchValue + `\\b`, 'i') : new RegExp(searchValue, 'i')
};
const getMatchingIndex = (text, matchRegex) => {
  return text?.match(matchRegex)?.index ?? -1;
}
function getNoOfOccurrences(string, subString, searchRegex) {
  string += "";
  subString += "";
  if (subString.length <= 0) return 0;
  var n = 0,
    pos = 0,
    index = 0,
    step = subString.length;
  while (true) {
    index = getMatchingIndex(string.slice(pos), searchRegex);
    if (index >= 0) {
      ++n;
      pos = pos + index + step;
    } else break;
  }
  return n;
}
export function useSearchOccurrences(columns, gridApiRef, columnApiRef, containerRef) {
  const [searchModalVisible, setSearchModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [gridContext, setGridContext] = useState({});
  const [gridData, setGridData] = useState([]);
  const [matchWholeWord, setMatchWholeWord] = useState(localStorage.getItem("matchWholeWordInFind") === "true");
  const searchRegex = getMatchRegex(searchValue, matchWholeWord);
  const handleToggleMatchWord = () => {
    const newMatchWholeWord = !matchWholeWord;
    setMatchWholeWord(newMatchWholeWord);
    localStorage.setItem("matchWholeWordInFind", newMatchWholeWord);
  }
  const newColumns = searchModalVisible ? columns.map((col) => ({
    ...col,
    cellRenderer: col.cellRenderer || HighlightCellRenderer
  })) : columns;
  const handleNext = useCallback(() => {
    if (gridContext?.searchOccurrences?.occurrences?.length > 1) {
      const activeOccurrenceIndex = gridContext?.searchOccurrences?.activeOccurrenceIndex || 0;
      const totalOccurrences = gridContext?.searchOccurrences?.occurrences?.length || 0;
      let nextActiveOccurrenceIndex = activeOccurrenceIndex + 1;
      if (nextActiveOccurrenceIndex >= totalOccurrences) {
        nextActiveOccurrenceIndex = 0;
      }
      setGridContext({
        searchOccurrences: {
          ...gridContext.searchOccurrences,
          ...{
            activeOccurrenceIndex: nextActiveOccurrenceIndex,
            currentOccurrence:
              gridContext?.searchOccurrences?.occurrences[nextActiveOccurrenceIndex],
          },
        },
      });
      gridApiRef.current?.ensureIndexVisible(
        gridContext?.searchOccurrences.occurrences[nextActiveOccurrenceIndex].rowIndex,
        "middle"
      );
    }
  }, [gridContext]);
  const handlePrev = useCallback(() => {
    if (gridContext?.searchOccurrences?.occurrences?.length > 1) {
      const activeOccurrenceIndex = gridContext?.searchOccurrences?.activeOccurrenceIndex || 0;
      const totalOccurrences = gridContext?.searchOccurrences?.occurrences?.length || 0;
      let prevActiveOccurrenceIndex = activeOccurrenceIndex - 1;
      if (prevActiveOccurrenceIndex < 0) {
        prevActiveOccurrenceIndex = totalOccurrences - 1;
      }
      setGridContext({
        searchOccurrences: {
          ...gridContext.searchOccurrences,
          ...{
            activeOccurrenceIndex: prevActiveOccurrenceIndex,
            currentOccurrence:
              gridContext?.searchOccurrences?.occurrences[prevActiveOccurrenceIndex],
          },
        },
      });
      gridApiRef.current?.ensureIndexVisible(
        gridContext?.searchOccurrences.occurrences[prevActiveOccurrenceIndex].rowIndex,
        "middle"
      );
    }
  }, [gridContext]);
  useEffect(() => {
    if (gridApiRef.current) {
      if (searchValue) {
        const displayedColumns = columnApiRef.current?.getAllDisplayedColumns();
        const occurrences = [];
        gridData.forEach((row, rowIndex) => {
          row.forEach((colValue, colIndex) => {
            const matchIndex = getMatchingIndex(colValue, searchRegex);
            if (matchIndex > -1) {
              let noOfOccurrences = getNoOfOccurrences(colValue, searchValue, searchRegex);
              for (let i = 0; i < noOfOccurrences; i++) {
                occurrences.push({
                  rowIndex,
                  colId: displayedColumns[colIndex]?.colId,
                  cellOccurrenceIndex: i,
                });
              }
            }
          });
        });
        setGridContext({
          searchOccurrences: {
            searchValue,
            searchRegex,
            matchWholeWord,
            occurrences,
            currentOccurrence: occurrences[0],
            activeOccurrenceIndex: 0,
          },
        });
        if (occurrences.length > 0) {
          gridApiRef.current?.ensureIndexVisible(occurrences[0].rowIndex, "middle");
        }
      } else {
        setGridContext({
          searchOccurrences: {
            searchValue,
            searchRegex,
            matchWholeWord,
          },
        });
      }
    }
  }, [searchValue, matchWholeWord, gridData]);
  useEffect(() => {
    if (gridApiRef.current) {
      // NOTE: Currently we are redrawing all full width cells on search
      // since the full width cell renderers do not gets called when context is updated
      // We can remove this code when ag grid fixes it
      setTimeout(() => {
        const fullWidthCellRows = gridApiRef.current.rowModel.rowsToDisplay.filter((node) =>
          node.isFullWidthCell(node)
        );
        gridApiRef.current.redrawRows({ rowNodes: fullWidthCellRows });
      }, 100)
    }
  }, [gridContext]);
  const onRowDataChanged = () => {
    // processes cell value to remove new line character `\n` from user data to get correct csv data  
    const processCellCallback = ({ value }) => {
      if (typeof value === "string") {
        return value?.replaceAll?.("\n", "")
      } else {
        return value
      }
    }
    setGridData(
      gridApiRef.current?.getDataAsCsv({ processCellCallback }).toLowerCase().split("\n").slice(1).map((l) => l.split(`",`).map((e) => e?.slice(1)))
    );
  };
  useEffect(() => {
    if (gridApiRef.current) {
      return () => {
        gridApiRef.current.removeEventListener("firstDataRendered", onRowDataChanged);
        gridApiRef.current.removeEventListener("rowDataChanged", onRowDataChanged);
      };
    }
  }, []);
  const returnFocusFunc = (triggerElementRef) => {
    if (gridContext.searchOccurrences?.currentOccurrence) {
      setTimeout(() => {
        ensureCellIsFocused(
          gridApiRef,
          gridContext.searchOccurrences?.currentOccurrence.rowIndex,
          gridContext.searchOccurrences?.currentOccurrence.colId
        )
      }, 100)
    } else {
      triggerElementRef.current?.focus?.();
    }
  }
  const handleCancel = useCallback(() => {
    setSearchModalVisible(false);
    setGridContext({ searchOccurrences: {} })
  }, [gridContext]);
  useMouseTrap(containerRef, {
    "ctrl+f, command+f": (e) => {
      e.preventDefault();
      setSearchModalVisible(true);
    },
  }, [containerRef]);
  const searchModalProps = {
    handleCancel,
    handleChange: (newValue) => {
      setSearchValue(newValue?.toLowerCase());
    },
    handleNext,
    handlePrev,
    handleToggleMatchWord,
    context: gridContext.searchOccurrences,
    returnFocusFunc
  };

  const onGridReady = (gridApi) => {
    if (gridApi.rowModel.rowsToDisplay?.length) {
      onRowDataChanged();
    }
    gridApi.addEventListener("firstDataRendered", onRowDataChanged);
    gridApi.addEventListener("rowDataChanged", onRowDataChanged);
  };

  return [
    gridContext,
    newColumns,
    HighlightFullWidthCellRenderer,
    onGridReady,
    searchModalVisible,
    searchModalProps,
  ];
}

const mapColumnDefsToAgGridColumnDefs = (columns, enableSearch, onEnter, rowSelection, headerCheckboxRef, handleChangeHeaderCheckbox) => {
  return [
    rowSelection ? <AgGridColumn
      suppressSizeToFit
      pinned="left"
      lockPinned
      colId="checkbox"
      width={40}
      checkboxSelection={true}
      headerComponentFramework={() => (
        <Checkbox ref={headerCheckboxRef} onChange={handleChangeHeaderCheckbox} />
      )}
      {...getCellProps(false)}
    /> : null,
    ...columns.map(
      ({
        id,
        title,
        width,
        autoSize,
        minWidth,
        isNavigable,
        align,
        searchType,
        cellStyle,
        tooltipValueGetter,
        children,
        ...rest
      }) => {
        return (
          (
            <AgGridColumn
              field={id}
              headerName={title}
              width={width}
              minWidth={minWidth}
              flex={!width && !autoSize ? 1 : undefined}
              onCellDoubleClicked={
                children
                  ? undefined
                  : (e) =>
                    onEnter?.(
                      e.data,
                      e.colDef.field,
                      e.api.getSelectedNodes().map((obj) => obj.data),
                      e.event,
                      e.api,
                      e.node,
                    )
              }
              {...getCellProps(isNavigable, enableSearch, searchType)}
              colSpan={({ data }) => (data._isBlankLine || data._isTitle) ? columns.length : 1}
              {...rest}
              cellStyle={cellStyle && typeof cellStyle === 'function' ? cellStyle : {
                textAlign: align,
                ...cellStyle,
              }}
              tooltipField={!children && !tooltipValueGetter ? id : null}
              tooltipValueGetter={tooltipValueGetter}
            >
              {children && mapColumnDefsToAgGridColumnDefs(children, enableSearch, onEnter)}
            </AgGridColumn>
          )
        )
      }
    )
  ].filter(el => el)
};

const checkIfRefnoMatches = (refno1, refno2) => {
  // if starts with alphabet prefix
  if (/[^$,\.\d]/.test(refno2)) { //eslint-disable-line 
    return refno1?.startsWith(refno2);
  }
  const refno1Parts = !refno1
    ? []
    : refno1.split(/[_//-]/).map((el) => extractNumberFromString(el));
  const refno2Parts = !refno2
    ? []
    : refno2.split(/[_//-]/).map((el) => extractNumberFromString(el));
  if (refno1Parts.length === refno2Parts.length) {
    return refno1Parts.every((refno1Part, i) => refno1Part === refno2Parts[i]);
  }
  return (
    refno1Parts.some((refno1Part) => refno2Parts.some((refno2Part) => refno1Part === refno2Part)) ||
    refno2Parts.some((refno2Part) => refno1Parts.some((refno1Part) => refno1Part === refno2Part))
  );
};

const getCellProps = (isNavigable, searchEnabled, searchType) => {
  const customTextComparator = (cellValue, searchValue) => {
    if (searchValue === "*") {
      return cellValue != "-" && cellValue != null;
    } else if (searchValue === "-") {
      return cellValue === "-" || cellValue === null || cellValue?.startsWith(searchValue);
    } else if (searchValue.startsWith("*")) {
      return String.prototype.includes.call(cellValue, searchValue.slice(1));
    } else if (searchValue.startsWith("!")) {
      return searchValue.length > 1 ? (!String.prototype.includes.call(cellValue, searchValue.slice(1))) : true;
    } else if (searchType === "refno") {
      return checkIfRefnoMatches(cellValue, searchValue)
    } else {
      return cellValue?.startsWith(searchValue);
    }
  };

  const searchableCellProps = {
    filter: true,
    floatingFilter: true,
    filterParams: {
      textMatcher: ({ value, filterText }) => customTextComparator(value, filterText)
    },
    floatingFilterComponentParams: { suppressFilterButton: true },
    suppressMenu: true,
  };

  const nonSearchableCellProps = {
    filter: false,
  };

  const navigableCellProps = {
    sortable: true,
  };
  const nonNavigableCellProps = {
    suppressNavigable: true,
    cellClass: "noFocus",
  };

  return Object.assign(
    {},
    isNavigable ? navigableCellProps : nonNavigableCellProps,
    searchEnabled ? searchableCellProps : nonSearchableCellProps
  );
};

const DataTable = ({
  data,
  columns,
  containerStyle,
  containerClass,
  rowSelection,
  toggleRowSelectionOnTab,
  onEnter,
  onCellKeyDown,
  showSummary,
  getSummary,
  onSelectionChanged,
  onRowSelected,
  onFocusedRowChanged,
  rowCountColumn,
  enableSearch = true,
  enableFind = false,
  style = {},
  onGridReady,
  autoFocus = true,
  disableRowFocus,
  rowHeight = 34,
  headerHeight,
  autoSizePadding = 4,
  gridOptions = {},
  forwardedRef,
  title,
  initialRowIndex,
  initialColId,
  initialFilterModel,
  initialSelectedNodes,
  initialSortModel,
  suppressFocus,
  suppressKeys = [],
  onFirstDataRendered,
  onRowDataChanged,
  fileName = "Report"
}) => {
  const gridApiRef = useRef();
  const columnApiRef = useRef();
  const focusedRowIdRef = useRef();
  const headerCheckboxRef = useRef();
  const containerRef = forwardedRef ? forwardedRef : useRef();
  const gridIdRef = useRef(nanoid());
  const { width } = useContainerDimensions(containerRef);
  const selectedNodesRef = useRef();
  const searchInputsRef = useRef({});
  const [
    gridContext,
    newColumns,
    fullWidthCellRenderer,
    onGridReadyForSearchOcccurrences,
    searchModalVisible,
    searchModalProps,
  ] = enableFind ? useSearchOccurrences(columns, gridApiRef, columnApiRef, containerRef) : [];

  const tableColumns = newColumns || columns;

  const agGridStyle = { ...style };
  if (width && width % 1 !== 0) {
    agGridStyle.width = Math.ceil(width);
  }

  const updateSummary = debounce(() => {
    if (gridApiRef.current && showSummary && getSummary) {
      const selectedRows = gridApiRef.current.getSelectedNodes().map((obj) => obj.data);
      const filteredRows = gridApiRef.current.rowModel.rowsToDisplay.map((obj) => obj.data);

      const filteredRowsSummary = getSummary(filteredRows);
      const pinnedBottomRows = [filteredRowsSummary];
      if (selectedRows.length) {
        const selectedRowsSummary = getSummary(selectedRows);
        pinnedBottomRows.unshift(selectedRowsSummary);
      }

      if (rowCountColumn) {
        pinnedBottomRows.forEach((obj, i) => {
          if (pinnedBottomRows.length == 1 || i == 1) {
            obj[rowCountColumn] = `Total rows (${filteredRows.length})`;
          } else {
            obj[rowCountColumn] = `Selected rows (${selectedRows.length})`;
          }
        });
      }
      gridApiRef.current.setPinnedBottomRowData(pinnedBottomRows);
    }
  }, 50);

  const handleOnSelectionChanged = debounce((e) => {
    selectedNodesRef.current = gridApiRef.current.getSelectedNodes();
    if (onSelectionChanged) {
      onSelectionChanged(e.api.getSelectedNodes().map((obj) => obj.data))
    }
    updateSummary();
  }, 50);

  const handleOnFilterModified = (e) => {
    // On search change the focus to the first row
    const filteredRowsCount = gridApiRef.current.rowModel.rowsToDisplay.length;
    const focusedCell = e.api.getFocusedCell();
    const rowIndex =
      (!focusedCell || focusedCell.rowIndex >= filteredRowsCount) ? 0 : focusedCell.rowIndex;
    ensureCellIsFocused(gridApiRef, rowIndex, e.column.getColId(), gridApiRef.current.rowModel.rowsToDisplay[0]?.id, containerRef);
    searchInputsRef.current[e.column.getColId()] = e.filterInstance?.appliedModel?.filter;
    if (showSummary) {
      updateSummary();
    }
  };

  const selectAllFilteredRows = () => {
    gridApiRef.current.selectAllFiltered();
  };

  const exportDataAsExcel = () => {
    const columnsToExport = columns.filter(obj => obj.id);
    let rowNodes = gridApiRef.current.rowModel.rowsToDisplay;
    const selectedRowNodes = gridApiRef.current.getSelectedNodes();
    if (selectedRowNodes.length > 0) {
      rowNodes = selectedRowNodes;
    }
    const rows = rowNodes
      .map(row => columnsToExport.map(col => {
        return ({ [col.title]: gridApiRef.current.getValue(col.id, row) })
      }).reduce((acc, curr) => ({ ...acc, ...curr }), {})
      )
    const summaryRow = gridApiRef.current.getPinnedBottomRow(0);
    rows.push(
      columnsToExport.map(col => {
        return ({ [col.title]: gridApiRef.current.getValue(col.id, summaryRow) })
      }).reduce((acc, curr) => ({ ...acc, ...curr }), {})
    );

    const ws = XLSX.utils.json_to_sheet(rows, {});
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
    const data = new Blob([buffer], { type: "application/vnd.ms-excel" })
    saveAs(data, fileName + ".xlsx");
  }

  const handleChangeHeaderCheckbox = (e) => {
    const { checked } = e.target;
    if (checked) {
      selectAllFilteredRows();
    } else {
      gridApiRef.current.deselectAll();
    }
  };

  const agGridColumns = useMemo(() =>
    mapColumnDefsToAgGridColumnDefs(tableColumns, enableSearch, onEnter, rowSelection, headerCheckboxRef, handleChangeHeaderCheckbox),
    [JSON.stringify(tableColumns.map(({ id, name, width, align }) => (id, name, width, align))), enableSearch, rowSelection, gridContext]
  )

  function suppressKeyboardEvents(params) {
    const KEY_SPACE = " ";
    const KEY_ARROW_UP = "ArrowUp";
    const KEY_ARROW_DOWN = "ArrowDown";
    const KEY_TAB = "Tab";

    const event = params.event;
    const keyPressed = event.key;
    if (keyPressed === KEY_SPACE && !event.shiftKey && !event.ctrlKey && !params.colDef.editable) {
      event.preventDefault();
      return true;
    }
    if (toggleRowSelectionOnTab && keyPressed === KEY_TAB) {
      event.preventDefault();
      return true;
    }
    if (keyPressed === KEY_ARROW_UP && params.node.rowIndex === 0) {
      return true;
    }
    if (
      keyPressed === KEY_ARROW_DOWN &&
      params.node.rowIndex === params.api.rowModel.rowsToDisplay.length - 1
    ) {
      return true;
    }
    if (suppressKeys.includes(keyPressed)) {
      event.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();
      return true;
    }
  }

  useEffect(() => {
    if (autoFocus && !suppressFocus && !data?.length) {
      if (containerRef.current) {
        containerRef.current.tabIndex = -1;
        containerRef.current.focus?.();
      }
    }
  }, [autoFocus, suppressFocus, data])

  useMouseTrap(containerRef, {
    "ctrl+a": () => {
      if (!rowSelection) {
        return;
      }
      const selectedRowsCount = gridApiRef.current.getSelectedNodes().length;
      if (selectedRowsCount === 0) {
        selectAllFilteredRows();
      } else {
        gridApiRef.current.deselectAll();
      }
    },
    "ctrl+shift+home": () => {
      gridApiRef.current.deselectAll();
    },
    "ctrl+e": () => exportDataAsExcel(),
  });

  useMouseTrap(
    { current: document.body },
    {
      backspace: (e) => {
        const api = gridApiRef.current;
        if (api) {
          const columnId = api.getFocusedCell()?.column?.colId;
          const displayedRowsCount = api.rowModel?.rowsToDisplay?.length;
          if (displayedRowsCount === 0 && columnId) {
            const filterInstance = api.getFilterInstance(columnId);
            if (filterInstance) {
              const currValue = String(filterInstance.getModel()?.filter ?? "");
              if (currValue) {
                const newValue = currValue.slice(0, -1);
                const newFilterModel = {
                  filterType: "text",
                  type: "contains",
                  filter: newValue,
                };
                e.preventDefault();
                e.stopImmediatePropagation();
                filterInstance.setModel(newFilterModel);
                api.onFilterChanged();
              }
            }
          }
        }
      },
      del: () => {
        const api = gridApiRef.current;
        if (api) {
          const columnId = api.getFocusedCell()?.column?.colId;
          const displayedRowsCount = api.rowModel?.rowsToDisplay?.length;
          if (displayedRowsCount === 0 && columnId) {
            const filterInstance = api.getFilterInstance(columnId);
            if (filterInstance) {
              const newFilterModel = {
                filterType: "text",
                type: "contains",
                filter: "",
              };
              filterInstance.setModel(newFilterModel);
              api.onFilterChanged();
            }
          }
        }
      },
    }
  );

  const autoSizeColumnsIfNeeded = useCallback(
    debounce((e) => {
      const showEllipsis = Array.from(
        document.querySelectorAll(`.grid${gridIdRef.current} .ag-cell`)
      ).some((el) => {
        return el.scrollWidth > el.clientWidth;
      });
      if (showEllipsis) {
        autoSizeColumns(e.columnApi, columns, gridApiRef.current, gridIdRef.current);
      }
    }, 1000),
    [columns]
  );

  // We have moved the logic of setting filters on typing from onCellKeydown to here
  // since there it was missing keystrokes on fast typing
  useEffect(() => {
    function handleKeydown(event) {
      // Note: This setTimeout is necessary since the activeElement changes to body for a while on typing
      setTimeout(() => {
        if (containerRef?.current?.contains(document.activeElement) && document.activeElement.nodeName !== "INPUT") {
          const keyPressed = event.key;
          const api = gridApiRef.current;
          const focusedCell = api?.getFocusedCell();
          const colDef = focusedCell?.column?.colDef;
          const columnId = colDef?.field;
          const isColumnEditable = colDef?.editable;
          if (enableSearch && !isColumnEditable && !suppressKeys.includes(keyPressed)) {
            if (
              (keyPressed === "Backspace" || keyPressed === "Delete" || keyPressed.length === 1) &&
              !event.ctrlKey &&
              !event.altKey
            ) {
              if (keyPressed === "Delete") {
                // NOTE: Calling filterInstance.setModel brings that column to focus
                // Hence we are sorting the columns such that the current focused column should come last
                const currFocusColId = api.getFocusedCell().column.getColId();
                columns
                  .slice()
                  .sort((a) => (a.id === currFocusColId ? 1 : -1))
                  .forEach((col) => {
                    if (col.id) {
                      const filterInstance = api.getFilterInstance(col.id);
                      if (filterInstance) {
                        const currValue = String(filterInstance.getModel()?.filter ?? "");
                        if (currValue) {
                          const newFilterModel = {
                            filterType: "text",
                            type: "startsWith",
                            filter: "",
                          };
                          filterInstance.setModel(newFilterModel);
                        }
                      }
                    }
                  });
                api.onFilterChanged();
              } else {
                const filterInstance = api.getFilterInstance(columnId);
                if (filterInstance) {
                  const currValue = String(filterInstance.getModel()?.filter ?? "");
                  let newValue = "";
                  if (keyPressed === "Backspace") {
                    newValue = currValue ? currValue.slice(0, -1) : currValue;
                  } else {
                    newValue = currValue + keyPressed;
                  }
                  const newFilterModel = {
                    filterType: "text",
                    type: "contains",
                    filter: newValue,
                  };
                  filterInstance.setModel(newFilterModel);
                  api.onFilterChanged();
                }
              }
            }
          }
        }
      }, 10)
    }
    document.addEventListener("keydown", handleKeydown)
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    }
  }, [])

  return (
    <div
      tabIndex={disableRowFocus ? undefined : 0}
      className={`ag-theme-balham ${disableRowFocus ? "no-focus" : ""
        } ag-container ${containerClass}`}
      style={containerStyle}
      ref={containerRef}
    >
      {title}
      <AgGridReact
        singleClickEdit
        onBodyScrollEnd={autoSizeColumnsIfNeeded}
        className={`grid${gridIdRef.current}`}
        autoSizePadding={autoSizePadding}
        containerStyle={agGridStyle}
        animateRows={false}
        suppressAnimationFrame
        suppressColumnVirtualisation={true}
        onCellFocused={
          onFocusedRowChanged
            ? (e) => {
              const rowNode = gridApiRef.current?.getDisplayedRowAtIndex(e.rowIndex);
              const rowId = rowNode?.id;
              if (rowId !== focusedRowIdRef.current) {
                onFocusedRowChanged(rowNode?.data);
              }
              focusedRowIdRef.current = rowId;
            }
            : undefined
        }
        enableBrowserTooltips
        rowHeight={rowHeight}
        headerHeight={headerHeight}
        rowSelection={rowSelection}
        suppressKeyboardEvent={suppressKeyboardEvents}
        onSelectionChanged={handleOnSelectionChanged}
        suppressRowClickSelection
        onNewColumnsLoaded={(e) => {
          if (gridApiRef.current) {
            setTimeout(() => {
              autoSizeColumns(e.columnApi, columns, e.api, gridIdRef.current)
            }, 100)
          }
        }}
        onGridReady={(e) => {
          gridApiRef.current = e.api;
          columnApiRef.current = e.columnApi;
          onGridReady?.(e.api);
          setTimeout(() => {
            onGridReadyForSearchOcccurrences?.(e.api, e.columnApi);
          }, 100);
          e.api.restoreFocus = () => {
            const focusedCell = e.api.getFocusedCell();
            if (focusedCell) {
              ensureCellIsFocused(gridApiRef, focusedCell.rowIndex, focusedCell.column.colId);
            }
          };
          e.api.exportDataAsExcel = exportDataAsExcel;
          e.api.updateSummary = updateSummary;
          autoSizeColumns(e.columnApi, columns, gridApiRef.current, gridIdRef.current);
        }}
        onFirstDataRendered={(e) => {
          if (initialFilterModel) {
            gridApiRef.current.setFilterModel(initialFilterModel);
          }
          if (initialSortModel) {
            e.columnApi.applyColumnState({ state: [{ colId: initialSortModel.colId, sort: initialSortModel.sort }] })
          }
          if (initialSelectedNodes?.length) {
            initialSelectedNodes.forEach(node => gridApiRef.current.getRowNode(node.id).setSelected(true));
          }
          if (autoFocus) {
            const rowIndex = initialRowIndex || 0;
            const colId = initialColId || getGridFirstNavigableColId(columns);
            ensureCellIsFocused(gridApiRef, rowIndex, colId);
          }
          if (showSummary) {
            updateSummary();
          }
          autoSizeColumns(e.columnApi, columns, gridApiRef.current, gridIdRef.current);
          onFirstDataRendered?.();
        }}
        onRowDataChanged={(e) => {
          if (!suppressFocus) {
            const focusedCell = gridApiRef.current?.getFocusedCell();
            const totalRows = gridApiRef.current?.rowModel.rowsToDisplay.length;
            if (focusedCell) {
              let focusedRowIndex = focusedCell?.rowIndex;
              let focusedColId = focusedCell?.column.colId;
              if (focusedRowIndex >= totalRows && totalRows > 0) {
                focusedRowIndex = totalRows - 1;
              }
              setTimeout(() => {
                if (autoFocus) {
                  ensureCellIsFocused(gridApiRef, focusedRowIndex, focusedColId, null, containerRef);
                }
                if (onFocusedRowChanged) {
                  onFocusedRowChanged(gridApiRef.current?.getDisplayedRowAtIndex(focusedRowIndex)?.data);
                }
              }, 100);
            }
            if (showSummary) {
              updateSummary();
            }
          }
          selectedNodesRef.current?.forEach(node => gridApiRef.current.getRowNode(node.id)?.setSelected(true));
          autoSizeColumnsIfNeeded(e);
          onRowDataChanged?.(e);
        }}
        rowData={data}
        onFilterModified={handleOnFilterModified}
        onRowSelected={(e) => {
          onRowSelected?.(e);
          if (e.data._isTitle) {
            const isGroupSelected = e.node.isSelected();
            const groupNodes = e.api.rowModel.rowsToDisplay.filter(
              obj => obj.data._parentGroupId === e.data._groupId || obj.data._groupId === e.data._groupId
            );
            const fullWidthGroupNodes = groupNodes.filter(node => node.isFullWidthCell());
            groupNodes.forEach(node => {
              const isNodeSelected = node.isSelected();
              if (isNodeSelected !== isGroupSelected) {
                node.setSelected(isGroupSelected);
              }
            })
            gridApiRef.current.redrawRows({ rowNodes: fullWidthGroupNodes })
            const focusedCell = e.api.getFocusedCell();
            gridApiRef.current.setFocusedCell(focusedCell.rowIndex, focusedCell.column.getColId())
          }
        }}
        onCellKeyDown={(e) => {
          const keyPressed = e.event?.key;
          const columnId = e.colDef?.field;

          if (keyPressed === "Enter") {
            onEnter?.(
              e.data,
              columnId,
              e.api.getSelectedNodes().map((obj) => obj.data),
              e.event,
              e.api,
              e.node,
            );
          }

          if (keyPressed.toUpperCase() === "C" && e.event.ctrlKey) {
            if (columnId) {
              navigator.clipboard.writeText(String(e.api.getValue(columnId, e.node)));
            } else {
              navigator.clipboard.writeText(String(e.data[columns[0].id]));
            }
          }

          if (rowSelection) {
            if (keyPressed === " ") {
              // Use Ctrl + Space for toggling selection
              if (e.event.ctrlKey) {
                e.node.setSelected(!e.node.isSelected());
                return;
              } else if (e.event.shiftKey) {
                // Shift + Space is used internally by ag grid for range selection
                return;
              } else {
                e.event.preventDefault();
                e.event.stopImmediatePropagation();
                e.event.stopPropagation();
              }
            }
            if (toggleRowSelectionOnTab && keyPressed === "Tab") {
              e.node.setSelected(!e.node.isSelected());
            }
            if (["ArrowDown", "ArrowUp"].includes(keyPressed) && e.event.shiftKey) {
              const selectedNodes = e.api.getSelectedNodes().sort((a, b) => a.rowIndex < b.rowIndex ? -1 : 1)
              const rowIndex = e.node.rowIndex;
              const lastSelectedNode = selectedNodes[selectedNodes.length - 1];
              const firstSelectedNode = selectedNodes[0];
              const isLastSelectedNodeOrLater = rowIndex >= lastSelectedNode?.rowIndex;
              const isFirstSelectedNodeOrBefore = rowIndex <= firstSelectedNode?.rowIndex;

              let incrementSelection = true;
              if (
                keyPressed === "ArrowUp" && isLastSelectedNodeOrLater ||
                keyPressed === "ArrowDown" && isFirstSelectedNodeOrBefore
              ) {
                incrementSelection = false;
              }
              if (incrementSelection) {
                e.node.setSelected(true);
                const nextNodeIndex = keyPressed === "ArrowDown" ? rowIndex + 1 : rowIndex - 1;
                const nextNode = e.api.getDisplayedRowAtIndex(nextNodeIndex);
                if (nextNode) {
                  nextNode.setSelected(true);
                }
              } else {
                e.node.setSelected(false);
              }
            }
          }
          onCellKeyDown?.({
            data: e.data,
            colId: e.column.colId,
            event: e.event,
            node: e.node,
            api: e.api,
          });
        }}
        fullWidthCellRenderer={fullWidthCellRenderer}
        isFullWidthRow={(params) => params.rowNode.data._isBlankLine || params.rowNode.data._isTitle}
        context={gridContext}
        {...gridOptions}
      >
        {agGridColumns}
      </AgGridReact>
      {searchModalVisible && <SearchOccurrencesModal {...searchModalProps} />}
    </div>
  );
};

export default DataTable;
